<template>
  <div class="on-demand-warp">
    <TopSearch />
    <van-tabs v-model="activeName" @change="changeTab" animated :swipeable="true" background="#f21313" color="#fff" title-inactive-color="#fff" title-active-color="#fff" class="tab-active-class">
      <van-tab class="tabs" v-for="(item,index) in tabs" :title="item.name" :key="index" :name="item.path">
        <keep-alive :include="['recommend','exclusive','original']">
          <router-view />
        </keep-alive>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
import "swiper/dist/css/swiper.css";
import TopSearch from "@/components/TopSearch/index.vue";
export default {
  name: "onDemand",
  components: {
    TopSearch,
  },
  data() {
    return {
      activeName: "/onDemand/recommend",
      tabs: [
        {
          path: "/onDemand/recommend",
          name: "推荐",
        },
        {
          path: "/onDemand/exclusive",
          name: "独家",
        },
        {
          path: "/onDemand/original",
          name: "原创",
        },
      ],
    };
  },
  created() {
    this.activeName = this.$route.path;
  },
  methods: {
    changeTab(name) {
      this.$router.push(name);
      sessionStorage.setItem("onDemand", name);
    },
  },
};
</script>
<style lang="scss" scoped>
.on-demand-warp {
  overflow: hidden;
  height: 100%;
  /deep/ .van-tabs__content--animated {
    overflow: visible !important;
    /* overflow: */
  }
  /deep/ .van-tab {
    font-size: 16px;
  }
  /deep/ .van-tab--active {
    font-size: 18px !important;
    font-weight: 600;
  }
  /deep/ .van-tabs__line {
    bottom: 18px;
    width: 26px;
    height: 5px;
    border-radius: 6px;
  }
}
</style>
